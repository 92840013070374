// Variable overrides

// Bran Variables
$color__brand: #e6485c;
$color__gris: #555362;
$color__gris-claro: #f6f6f6;
$color__verde: #96b773;
$color__bco: #fff;
$color__negro: #333;

$blue: #3490dc;
$indigo: #6574cd;
$purple: #9561e2;
$pink: #f66d9b;
$red: #e3342f;
$orange: #f6993f;
$yellow: #ffed4a;
$green: #38c172;
$teal: #4dc0b5;
$cyan: #6cb2eb;

/**
* ReactStrap Variables
*/
$primary: $color__brand;
$secondary: $color__gris;
$success: $color__verde;
$info: $blue;
$warning: #ffc107;
$danger: $red;
$light: #f8f9fa;
$dark: $color__negro;
