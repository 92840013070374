// Here you can add other styles
.sidebar {
	.nav-item {
		&.inactive {
			.nav-link {
				background: transparent;
				&:hover {
					background: $primary;
				}
			}
			.nav-icon {
				color: $sidebar-nav-link-icon-color !important;
				&:hover {
					color: $light !important;
				}
			}
		}
	}
}
